import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './TransactionsTable.module.scss';
import { useTranslation } from 'react-i18next';
import Tooltip from '~/components/Shared/Tooltip/Tooltip';
import InfoIcon from '~/assets/svg/newSvg/info-icon.svg?react';
import CustomCheckbox from '~/components/Shared/CustomCheckbox/CustomCheckbox';
import { useContext } from 'react';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { renderStatus } from '../../utils';

export interface Transaction {
  id: number;
  user_name: string;
  user_id: number;
  created_at: string;
  status_title: string;
  total_sum: string;
  food_name: string;
  has_cancel_wares: boolean;
}

interface Props {
  transactions: Transaction[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  handleToggleAll: () => void;
  handleSelect: (id: number) => void;
  selectedItems: number[];
}

export const TransactionsTable = ({
  transactions,
  sort,
  setSort,
  handleToggleAll,
  handleSelect,
  selectedItems,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useContext(AuthenticationContext);
  const isDeleteAvailable = checkIfUserSuperAdmin(user?.data.role_name);

  return (
    <div className={styles.tableWrapper}>
      <Table>
        <TableHead sort={sort} setSort={setSort}>
          {isDeleteAvailable ? (
            <TableCellHead
              className={styles.tableCellHead}
              sortable={false}
              field="checkbox"
            >
              <CustomCheckbox
                isChecked={
                  selectedItems.length === transactions.length &&
                  !!transactions.length
                }
                onChange={handleToggleAll}
              />
            </TableCellHead>
          ) : (
            ''
          )}
          <TableCellHead field="user_name">{t('employee')}</TableCellHead>
          <TableCellHead field="user_id">{t('employee_id')}</TableCellHead>
          <TableCellHead field="id">{t('order_number')}</TableCellHead>
          <TableCellHead field="created_at">{t('order_date')}</TableCellHead>
          <TableCellHead field="status">{t('status')}</TableCellHead>
          <TableCellHead field="food_name">{t('food_object')}</TableCellHead>
          <TableCellHead field="total_sum">{t('order_price')}</TableCellHead>
          <TableCellHead sortable={false} field="actions">
            {t('action')}
          </TableCellHead>
        </TableHead>
        <TableBody>
          {transactions.map(
            ({
              id,
              user_name,
              user_id,
              created_at,
              status_title,
              food_name,
              total_sum,
              has_cancel_wares,
            }: Transaction) => (
              <TableRow key={id}>
                {isDeleteAvailable ? (
                  <TableCell>
                    <CustomCheckbox
                      isChecked={selectedItems.includes(id)}
                      onChange={() => handleSelect(id)}
                    />
                  </TableCell>
                ) : (
                  ''
                )}
                <TableCell>{user_name}</TableCell>
                <TableCell>{user_id}</TableCell>
                <TableCell>#{id}</TableCell>
                <TableCell>{created_at}</TableCell>
                <TableCell>
                  <div className={styles.statusCell}>
                    {renderStatus(status_title)}

                    {has_cancel_wares ? (
                      <Tooltip
                        tooltipStyle={styles.tooltipStyle}
                        className={styles.transactionsTooltip}
                        title={t('request_closed_tooltip')}
                      >
                        <InfoIcon className={styles.tooltipIcon} />
                      </Tooltip>
                    ) : (
                      ''
                    )}
                  </div>
                </TableCell>
                <TableCell>{food_name}</TableCell>

                <TableCell>{total_sum} &#8381;</TableCell>
                <TableCell>
                  <div className={styles.actions}>
                    <IconButtonLink
                      to={`/transactions/${id}`}
                      className={styles.btnLink}
                    >
                      <Edit />
                    </IconButtonLink>
                  </div>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
};
